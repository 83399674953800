import React from "react"
import Layout from "../components/Layout/Layout"
import BannerListing from "../components/BannerListing/BannerListing"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Container } from "react-bootstrap"

const Sitemap = () => {
  const data = useStaticQuery(graphql`
    query {
      allStrapiMenu(filter: { publish: { eq: true } }) {
        nodes {
          slug
          publish
          title
          link_type
          id
          external_link
          strapi_id
        }
      }
    }
  `)

  const menu = data.allStrapiMenu.nodes

  const exclude = [
    "home",
    "privacy-policy",
    "terms-and-conditions",
    "sitemap",
    "cookie-policy",
    "dummy-menu",
  ]

  menu.forEach((item, index) => {
    if (exclude.includes(item.slug)) {
      menu.splice(index, 1)
    }
  })

  return (
    <div className="sitemap-page">
      <BannerListing title="Sitemap" />
      <Container className="sitemap-menu-section">
        {menu.map((item, index) => {
          return (
            <h2 key={index}>
              <Link to={"/" + item.slug + "/"} className="menu-item">
                {item.title}
              </Link>
            </h2>
          )
        })}
      </Container>
    </div>
  )
}

export default Sitemap
