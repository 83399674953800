import React from "react"
import "./BannerListing.scss"
import { parseHTML } from "../../common/utils/utils"
import { Container } from "react-bootstrap"
import { motion, useInView } from "framer-motion"
import { contentVariant } from "../../common/animations"

const BannerListing = ({ description, title }) => {
  return (
    <motion.div
      className="listing-banner-wrap"
      variants={contentVariant}
      initial="initial"
      animate="animate"
    >
      <Container className="listing-banner-container">
        <h1 className="title">{title}</h1>
        <div className="description">
          {parseHTML(description?.data?.description)}
        </div>
      </Container>
    </motion.div>
  )
}

export default BannerListing
